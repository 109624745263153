import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import config from '../../data/SiteConfig'
import styles from './template.module.scss'
import _ from 'lodash'

import Title from '../elements/Title'

import { PageHeader, Tag } from 'antd'

const TagTemplate = ({ data, pageContext }) => (
  <Layout activeSidebar={pageContext.tag}>
    <main>
      <Helmet title={`Posts tagged as "${pageContext.tag}" | ${config.siteTitle}`} />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        tags={[
          <Tag color="geekblue" key="total">{`${data.allMarkdownRemark.totalCount} Articles`}</Tag>,
        ]}
        title={<Title>{_.startCase(pageContext.tag)}</Title>} 
        className={styles.pageHeader} />
      <PostListing postEdges={data.allMarkdownRemark.edges} />
    </main>
  </Layout>
)
export default TagTemplate

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date(formatString: "DD MMM YYYY", locale: "fr")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
          }
        }
      }
    }
  }
`
